const firebaseConfig = {
  projectId: "marvilla-hpgxm",
  appId: "1:391388985633:web:19b3c234b608b145c12660",
  databaseURL:
    "https://marvilla-hpgxm-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "marvilla-hpgxm.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyD34bzuV17cTBlwq3QUeDgXqO3-aM7GLcE",
  authDomain: "marvilla-hpgxm.firebaseapp.com",
  messagingSenderId: "391388985633",
  measurementId: "G-KBKYPL52CD",
};

export default firebaseConfig;
